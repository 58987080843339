<template>
  <section class="page-content">
    <h1>Kontakt</h1>
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
        <p class="subtitle">
          Vår oppgave et å hjelpe deg på best mulig måte, så raskt som mulig. Under finner du informasjon om vanlige spørsmål og svar som våre brukere oftest lurer på.
          Om du ikke finner svar på ditt spørsmål over, kontakt oss på, så vil vi bistå deg snarlig
        </p>
        <p class="subtitle">Kontakt <a href="mailto:support@hytta.io">support@hytta.io</a></p>
      </column>
    </row>
  </section>
</template>
<script>
export default {

}
</script>
